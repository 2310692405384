.container
  @apply flex flex-col items-center max-w-38.8 w-full

@function circleGradient($bg-color)
  $bg-gradient: linear-gradient($bg-color, $bg-color) padding-box
  $border-gradient: linear-gradient(to right, theme("colors.linear.800"), theme("colors.linear.900")) border-box

  @return $bg-gradient, $border-gradient

.circle
  @apply max-w-14 max-h-14 w-screen h-screen rounded-full flex mb-4 justify-center items-center

.icon
  @apply from-linear-900 to-linear-800 w-9 h-9

.largeBottomMargin
  @apply mb-2.3

.message
  @apply font-gilroy font-heading text-headingsm text-text-primary mb-2 text-center mb-1

.description
  @apply font-gilroy text-lg text-text-secondary text-center mb-2

.lightText
  @apply text-white
