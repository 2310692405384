.container
  @apply border-2 border-solid border-transparent

@function borderGradient($direction, $bg-color-from, $bg-color-to: $bg-color-from)
  $bg-gradient: linear-gradient($bg-color-from, $bg-color-to) padding-box
  $border-gradient: linear-gradient($direction, theme("colors.linear.800"), theme("colors.linear.900")) border-box

  @return $bg-gradient, $border-gradient

.gray
  background: borderGradient(to right, theme('colors.grays.900'))

  &.flip
    background: borderGradient(to left, theme('colors.grays.900'))

.white
  background: borderGradient(to right, theme('colors.white'))

  &.flip
    background: borderGradient(to left, theme('colors.white'))

.light
  background: borderGradient(to right, theme('colors.grays.100'))

  &.flip
    background: borderGradient(to left, theme('colors.grays.100'))

.dark
  background: borderGradient(to right, theme('colors.background.900'))

  &.flip
    background: borderGradient(to left, theme('colors.background.900'))
