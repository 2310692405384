.container
  @apply rounded-full font-gilroy font-large text-white

.primary
  @apply bg-primary-900 hover:bg-primary-700 active:bg-primary-700

.secondary
  @apply bg-secondary-900 hover:bg-secondary-700 active:bg-secondary-700

.danger
  @apply bg-accent-900

.white
  @apply bg-white text-text-secondary
  &:hover
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2)

.large
  @apply px-2.4 py-1.8 text-xl

.small
  @apply px-1.6 py-1 text-lg

.disabled
  @apply bg-grays-500 hover:bg-grays-500 active:bg-grays-500 cursor-default

.bounded
  @apply w-max

.linkButton
  @apply text-center
